<template>
  <v-container>
    <v-row>
      <v-col class="text-left">
        <v-btn small color="warning" @click="goBack()">
          <v-icon>mdi-arrow-left</v-icon>Kembali
        </v-btn>
      </v-col>
      <v-col class="text-right">
       &nbsp;
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-4 mx-lg-auto"
        outlined
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="postForm.subject"
              :rules="[rules.required]"
              label="Nama Case"
            ></v-text-field>  
          </v-col>

           <v-col
          cols="12"
            md="12"
            v-if="currrole"
          >

            <v-autocomplete
            
              :readonly="currrole.rolename != 'sekretaris' && currrole.rolename != 'admin'"
              v-model="postForm.hrorganizations"
              :items="dataListOrganization"
              label="Pilih Organisasi"
              item-text="text"
              item-value="value"
            ></v-autocomplete>
      
          </v-col>

        </v-row>
        <v-row>
          <v-col>

            <v-btn
              color="warning"
              class="mr-4"
              @click="resetForm"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="error"
              class="mr-4"
              @click="goBack()"
            >
              Batal
            </v-btn>

            <v-btn v-if="!isEdit" :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveAddForm()"
            >
              Simpan
            </v-btn>
            <v-btn v-else :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveEditForm()"
            >
              Ubah
            </v-btn>
      
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="isupLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">Uploading File</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                            v-model="uploadPercentage"
                            height="25"
                            reactive
                          >
                          <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
                        </v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn v-if="!isEdit" color="blue darken-1" @click="insertData()">Submit</v-btn>
            <v-btn v-else color="blue darken-1" @click="updateData()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import { insertCaseSurat, getCaseSuratByID, updateCaseSurat } from '@/api/casesurat'
import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import axios from 'axios'
import shortguid from 'short-uuid'


const defaultForm = {
  subject: '',
  caseGUID: '',
  hrorganizations: '',
  hruserpositions_created: 0,
  hruserpositions_updated: 0,
  thang: '',
  deleted: 0,
  userCreator:0,
  userEditor: 0
}

export default {
  name: 'FormCase',
  components: {
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      postForm: Object.assign({}, defaultForm),
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      rules: {
        required: v => !!v || 'Required.',
        maxlength: v => v.length <= 300 || 'Max 300 characters',
      },
      uploadPercentage: 0,
      isupLoading: false,
      isLoading: false,
      dialogConfirm: false,
      dataListOrganization: [],
      dataUser: [],
      currrole: []
    }
  },
  created () {

    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id
      this.fetchData(id)
    }
    else {
      this.postForm = Object.assign({})
    }
  },
  destroyed () {
  },
  mounted () {
    const vm = this
    // var now = moment().format('YYYY-MM-DD')

    vm.currrole = secureStorage.getItem('currRole')
    // console.log(vm.currrole)
    if(vm.currrole){
      if(vm.currrole.hrorganization){
        vm.postForm.hrorganizations = vm.currrole.hrorganization.id
      }
    }

    vm.dataUser = secureStorage.getItem('user')
    if (vm.dataUser !== null) {
      // console.log(vm.dataUser)
      if(vm.dataUser.role !== null) {
        if(vm.dataUser.role.id === 1) {
          vm.isAdmin = true
        }
      }
    }

    vm.postForm.thang = moment().format('YYYY')

    vm.getOrgData()
  },
  computed: {

  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    resetForm () {
      this.$refs.form.reset()
    },
    getOrgData(){
      const self = this
      self.dataListOrganization = []
      var rData = secureStorage.getItem('orgall')
      if(rData != null){
        var array = []
        rData.forEach(element => {
          if(element.level == 'Main_PD' || element.level == 'Main_VPD') element.level = ''
          var tmp = { value: element.id, text: element.level + ' ' + element.structure_name + ' ('+ element.unit_code + ')' }
          array.push(tmp)
        })
        self.dataListOrganization = array
      }
    },
    saveAddForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        delete vm.postForm.id
        vm.postForm.hruserpositions_created = vm.currrole.id
        vm.postForm.hruserpositions_updated = vm.currrole.id
        vm.postForm.deleted = 0

        vm.postForm.userCreator = vm.dataUser.id
        vm.postForm.userEditor = vm.dataUser.id

        var translator = shortguid()
        vm.postForm.caseGUID = translator.new()
        // console.log(vm.postForm)
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    saveEditForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        vm.postForm.hruserpositions_updated = vm.currrole.id
        vm.postForm.userEditor = vm.dataUser.id
        
        // console.log(vm.postForm)
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    
    fetchData (id) {
      const vm = this
      vm.isLoading = true
      getCaseSuratByID(id).then(response => {
        vm.postForm = response.data
        // console.log(vm.postForm)
        vm.postForm.hrorganizations = vm.postForm.hrorganizations.id
        delete vm.postForm.created_at
        delete vm.postForm.updated_at

        vm.isLoading = false
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
    insertData () {
      const vm = this
      vm.dialogConfirm = false

      // console.log('insert:',vm.postForm)
      vm.isLoading = true
      insertCaseSurat(vm.postForm).then(response => {
        var data = response.data
        vm.postForm = data
        
        vm.goBack()
        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Submit Data BERHASIL.', timeout: 2000
        }
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Submit Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    updateData () {
      const vm = this
      vm.dialogConfirm = false

      var idb = vm.postForm.id
      // console.log('id Data: ', idb)
      vm.isLoading = true
      // console.log('update:',vm.postForm)
      updateCaseSurat(vm.postForm, idb).then(response => {
        // console.log('Data', response.data)
        var data = response.data
        vm.postForm = data

        vm.goBack()
        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Update Data BERHASIL.', timeout: 2000
        }
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Update Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    
  }
}

</script>
